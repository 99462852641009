function saveAccessToken(accessToken) {
  return localStorage.setItem('access_token', accessToken);
}
function loadAccessToken() {
  return localStorage.getItem('access_token');
}
function removeAccessToken() {
  return localStorage.removeItem('access_token');
}

export {
  saveAccessToken,
  loadAccessToken,
  removeAccessToken
};
