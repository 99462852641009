// in src/users.js
import React, { Fragment, Component } from 'react';
import { Route } from 'react-router';
import Drawer from '@material-ui/core/Drawer';
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    TextInput,
    Filter,
    Show,
    ShowButton,
    SimpleShowLayout,
    AutocompleteInput,
    ReferenceInput,
    ReferenceField,
    DateField,
    BooleanField,
    RichTextField
} from 'react-admin';
import StarRatingField from '../components/StarRatingField'
import ReviewEdit from "./ReviewEdit"

const ReviewFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput source="user_id" reference="users" label="Reviewer" alwaysOn>
            <AutocompleteInput optionText="nickname"/>
        </ReferenceInput>
        <ReferenceInput source="product_id" reference="products" alwaysOn>
            <AutocompleteInput optionText="title"/>
        </ReferenceInput>
        <TextInput source="text" alwaysOn/>
    </Filter>
);
export const ReviewShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <TextField label="이름" source="name"/>
            <TextField label="닉네임" source="username"/>
            <EmailField label="이메일" source="email"/>
            <BooleanField label="전문가 여부" source="is_expert"/>
            <TextField label="보유 코인" source="coin"/>
            <ShowButton/>
        </SimpleShowLayout>
    </Show>
);

class _ReviewList extends Component {
    render() {
        const props = this.props;
        return (
            <Fragment>
                <List
                    {...props}
                    filters={<ReviewFilter />}
                    perPage={25}
                    sort={{field: 'id', order: 'DESC'}}
                >
                    <Datagrid
                        rowClick="edit"
                    >
                        <TextField source="id"/>
                        <ReferenceField
                            reference="users"
                            source="user_id"
                            label="posted by"
                            link="show">
                            <TextField source="username"/>
                        </ReferenceField>
                        <ReferenceField
                            reference="products"
                            source="product_id"
                            label="product"
                            link="edit">
                            <TextField source="title"/>
                        </ReferenceField>
                        <StarRatingField label="Rating"/>
                        <RichTextField label="Text" source="text"/>
                        <DateField label="created at" source="createdAt"/>
                    </Datagrid>
                </List>
                <Route path="/reviews/:id">
                    {({history,match}) => {
                        const isMatch = match && match.params && match.params.id
                        const id = (match && match.params) ? match.params.id : null
                        return (
                            <Drawer
                                variant="persistent"
                                open={isMatch}
                                anchor="right"
                                onClose={this.handleClose}
                            >
                                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                                {isMatch ? (
                                    <ReviewEdit
                                        id={id}
                                        onCancel={() => this.handleClose(history)}
                                        {...props}
                                    />
                                ) : null}
                            </Drawer>
                        );
                    }}
                </Route>
            </Fragment>
        );
    }

    handleClose = (history) => {
        history.push('/reviews');
    };
}

export const ReviewList = _ReviewList